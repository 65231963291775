import React from 'react';
import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import TooltipStyled from 'features/PatientGuideBook/components/HowToReadResultsContainer/TooltipStyled';

interface ITextWithTooltipProps {
  text: string;
  tooltipText: string;
  open: boolean;
  sx?: SxProps;
  tooltipSize?: number;
}

const TextWithTooltip = ({ text, tooltipText, sx, tooltipSize, open }: ITextWithTooltipProps): JSX.Element => {
  return (
    <Stack direction="row" spacing={1} sx={sx}>
      <Typography sx={{ b: { fontWeight: 'medium' } }} dangerouslySetInnerHTML={{ __html: text }} />
      <TooltipStyled open={open} title={tooltipText} tooltipSize={tooltipSize} />
    </Stack>
  );
};

export default TextWithTooltip;
