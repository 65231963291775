import React from 'react';
import { useRouter } from 'next/router';
import { BreadcrumbJsonLd } from 'next-seo';
import { Box, Breadcrumbs, Container, Grid, Typography } from '@mui/material';

import NextLink from 'components/link/NextLink';

export interface BreadcrumbData {
  name: string;
  url?: string;
}

interface Props {
  breadcrumbs: BreadcrumbData[];
  startPage?: BreadcrumbData;
}

const Breadcrumb = ({ breadcrumbs, startPage = { name: 'Strona główna', url: '/pacjent' } }: Props): JSX.Element => {
  const { asPath } = useRouter();

  const breadcrumbJson = breadcrumbs.map((breadcrumb, index) => ({
    position: index + 2,
    name: breadcrumb.name,
    item: `${process.env.NEXT_PUBLIC_URL || ''}${breadcrumb.url || asPath}`,
  }));

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box py={4}>
            <Breadcrumbs aria-label="breadcrumb">
              <NextLink color="text.primary" href={startPage.url!}>
                {startPage.name}
              </NextLink>
              {breadcrumbs.map((breadcrumb) => {
                return breadcrumb.url ? (
                  <NextLink
                    key={breadcrumb.name}
                    color="text.primary"
                    href={breadcrumb.url}
                    dangerouslySetInnerHTML={{ __html: breadcrumb.name }}
                  />
                ) : (
                  <Typography
                    key={breadcrumb.name}
                    color="grey.300"
                    dangerouslySetInnerHTML={{ __html: breadcrumb.name }}
                  />
                );
              })}
            </Breadcrumbs>
          </Box>
          <BreadcrumbJsonLd
            itemListElements={[
              {
                position: 1,
                name: 'Sklep',
                item: `${process.env.NEXT_PUBLIC_URL || ''}/`,
              },
              ...breadcrumbJson,
            ]}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Breadcrumb;
