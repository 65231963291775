import React from 'react';
import { GetStaticProps, NextPage } from 'next';
import Head from 'next/head';
import { NextSeo } from 'next-seo';
import HowToReadResultsContainer from 'features/PatientGuideBook/components/HowToReadResultsContainer';

import { getYoastSEO, SEOPage } from 'services/getYoastSEO';

import Breadcrumb, { BreadcrumbData } from 'components/Breadcrumb/Breadcrumb';
import Layout from 'components/Layout/Layout';

interface Props {
  yoast: SEOPage;
}

const ZasadyPobieraniaKrwiPage: NextPage<Props> = ({ yoast }) => {
  const breadcrumbs: BreadcrumbData[] = [
    {
      name: 'Poradnik pacjenta',
      url: '/pacjent/poradnik-pacjenta/',
    },
    { name: 'Jak czytać wyniki' },
  ];
  return (
    <Layout activeName="Strefa wiedzy" hideSponsors>
      <Head key="yoast-schema">
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(yoast.yoast_head_json.schema) }}
        ></script>
      </Head>
      <NextSeo
        title={yoast.yoast_head_json.title || yoast.yoast_head_json.og_title}
        description={yoast.yoast_head_json.description || yoast.yoast_head_json.og_description}
        canonical={yoast.yoast_head_json.canonical}
        openGraph={{
          title: yoast.yoast_head_json.og_title,
          description: yoast.yoast_head_json.og_description,
          site_name: yoast.yoast_head_json.og_site_name,
          type: yoast.yoast_head_json.og_type,
          url: yoast.yoast_head_json.og_url,
        }}
      />
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <HowToReadResultsContainer />
    </Layout>
  );
};

export const getStaticProps: GetStaticProps<Props> = async () => {
  const yoast = await getYoastSEO('jak-czytac-wyniki', 'pages', 'pacjent');

  return { props: { yoast } };
};

export default ZasadyPobieraniaKrwiPage;
