import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/system';
import TextWithTooltip from 'features/PatientGuideBook/components/HowToReadResultsContainer/TextWithTooltip';

interface ITooltipsWithTextSectionProps {
  data: { text: string; tooltipText: string; tooltipSize?: number; description: string; open: boolean }[];
}

const TooltipsWithTextSection = ({ data }: ITooltipsWithTextSectionProps): JSX.Element => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <Stack spacing={4} sx={{ display: { xs: 'none', md: 'block' } }}>
        <Stack
          spacing={6}
          direction="row"
          justifyContent="space-between"
          sx={{
            '&>*': { flexBasis: `${100 / Math.floor(data.length)}%`, justifyContent: 'center' },
            '&>*:first-child': { justifyContent: 'flex-start' },
          }}
        >
          {data.map((single, index) => (
            <TextWithTooltip
              key={index}
              open={single.open && isDesktop}
              text={single.text}
              tooltipText={single.tooltipText}
              tooltipSize={single.tooltipSize}
            />
          ))}
        </Stack>
        <Divider sx={{ bgcolor: 'grey.50' }} />
        <Stack
          spacing={6}
          direction="row"
          justifyContent="space-between"
          sx={{
            '&>*': { flexBasis: `${100 / Math.floor(data.length)}%`, justifyContent: 'center', textAlign: 'center' },
            '&>*:first-child': { textAlign: 'left' },
          }}
        >
          {data.map((single, index) => (
            <Typography key={index} fontWeight="medium" dangerouslySetInnerHTML={{ __html: single.description }} />
          ))}
        </Stack>
      </Stack>
      <Stack
        spacing={4}
        alignItems="center"
        sx={{ display: { xs: 'flex', md: 'none' }, '&>*': { alignItems: 'center' } }}
      >
        {data.map((single, index) => (
          <Stack key={index} spacing={2}>
            <TextWithTooltip
              open={single.open && !isDesktop}
              text={single.text}
              tooltipText={single.tooltipText}
              tooltipSize={single.tooltipSize}
            />
            <Typography
              fontWeight="medium"
              textAlign="center"
              dangerouslySetInnerHTML={{ __html: single.description }}
            />
          </Stack>
        ))}
      </Stack>
    </>
  );
};

export default TooltipsWithTextSection;
