import React, { useRef, useState } from 'react';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { Box, Tooltip, Typography } from '@mui/material';

interface ITooltipStyledProps {
  open: boolean;
  title: string;
  tooltipSize?: number;
}

const TooltipStyled = ({ title, tooltipSize = 290, open }: ITooltipStyledProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const tooltipRef = useRef<HTMLDivElement>(null);

  if (open && tooltipRef.current) {
    tooltipRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <Tooltip
      open={open || isOpen}
      title={
        <Typography component="span" variant="h6" fontWeight="normal" dangerouslySetInnerHTML={{ __html: title }} />
      }
      placement="top-start"
      componentsProps={{ tooltip: { sx: { maxWidth: tooltipSize } } }}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      sx={{ scrollMarginTop: '1200px', scrollSnapMarginTop: '1200px' }}
    >
      <Box
        ref={tooltipRef}
        color="primary.main"
        width="fit-content"
        height={24}
        sx={{ cursor: 'help', scrollMarginTop: '500px', scrollSnapMarginTop: '500px' }}
      >
        <HelpRoundedIcon color="inherit" sx={{ width: 20, height: 20 }} />
      </Box>
    </Tooltip>
  );
};

export default TooltipStyled;
