import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { Box, Divider, Stack, Typography } from '@mui/material';
import LeftRightContainer from 'features/PatientGuideBook/components/HowToReadResultsContainer/LeftRightContainer';
import LeftRightText from 'features/PatientGuideBook/components/HowToReadResultsContainer/LeftRightText';
import LeftRightWrapper from 'features/PatientGuideBook/components/HowToReadResultsContainer/LeftRightWrapper';
import TextWithTooltip from 'features/PatientGuideBook/components/HowToReadResultsContainer/TextWithTooltip';
import TooltipStyled from 'features/PatientGuideBook/components/HowToReadResultsContainer/TooltipStyled';
import TooltipsWithTextSection from 'features/PatientGuideBook/components/HowToReadResultsContainer/TooltipsWithTextSection';
import DiagLogo from 'public/images/logo/logo.svg';
import BarCode from 'public/images/pacjent/poradnik-pacjenta/barcode.svg';

interface IResultPresentationProps {
  start: boolean;
}

const ResultPresentation = ({ start }: IResultPresentationProps): JSX.Element => {
  const [currentStep, setCurrentStep] = useState(0);
  const [lastStep, setLastStep] = useState(0);

  const intervalHandler = (): void => {
    if (currentStep >= 10) {
      setCurrentStep(0);
    } else {
      setCurrentStep((prevValue) => prevValue + 1);
    }
  };

  useEffect(() => {
    if (!start) {
      // If user clicks stop; set last step to last known value before making it 0
      if (currentStep !== 0) {
        setLastStep(currentStep);
      }

      // If user clicks stop; set step to 0 to hide all tooltips
      setCurrentStep(0);
      return;
    }

    // If last step is set && user starts presentation
    if (lastStep !== 0 && currentStep <= 1) {
      setCurrentStep(lastStep);
    }

    // Skip 0 step & immediately show first tooltip
    if (currentStep === 0) {
      setCurrentStep(1);
    }

    const interval = setInterval(intervalHandler, 6000);

    return () => clearInterval(interval);

    // eslint-disable-next-line
  }, [start, currentStep]);

  return (
    <Box maxWidth={1248} width="100%">
      <Stack spacing={20}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={6} justifyContent="space-between" width="100%">
          <Stack spacing={4}>
            <Image src={DiagLogo} alt="Logo Diagnostyka S.A" />
            <Stack spacing={1} color="text.secondary">
              <Typography variant="h6">Diagnostyka S. A.</Typography>
              <Typography>ul. prof. Michała Życzkowskiego 16, 31-864 Kraków</Typography>
              <Typography>tel.: 12 295 01 00</Typography>
            </Stack>
          </Stack>
          <Stack spacing={{ xs: 10, md: 12 }}>
            <Image src={BarCode} alt="Przykładowy kod kreskowy" />
            <Typography>Nr księgi rejestrowej Podmiotu Leczniczego 000000005831</Typography>
          </Stack>
        </Stack>
        <Stack spacing={6}>
          <Stack spacing={1} textAlign="center">
            <Typography>Laboratorium oddziału Kraków</Typography>
            <Typography>
              ul. Życzkowskiego 16, 31-864 Kraków <br />
              Zakład Diagnostyki Laboratoryjnej (533)
            </Typography>
            <Typography variant="h6">SPRAWOZDANIE Z BADANIA</Typography>
          </Stack>
          <Divider sx={{ bgcolor: 'grey.50' }} />
          <LeftRightContainer>
            <LeftRightWrapper>
              <LeftRightText leftText="Zlecający:" rightText="Badanie płatne" />
              <LeftRightText leftText="Oddział:" rightText="Oddział Kraków" />
              <LeftRightText leftText="Lekarz kier.:" />
              <LeftRightText leftText="Odbiorca wyniku:" rightText="Odbiór osobisty" />
            </LeftRightWrapper>
            <LeftRightWrapper>
              <LeftRightText leftText="Kod kontrahenta:" rightText="1" />
              <LeftRightText leftText="Kod oddziału:" rightText="2020" />
              <LeftRightText leftText="Data rej.:" rightText="2014-12-15" />
              <LeftRightText leftText="Data/godz. pobrania:" rightText="2014-12-15 08:32" />
            </LeftRightWrapper>
          </LeftRightContainer>
          <Divider sx={{ bgcolor: 'grey.50' }} />
          <LeftRightContainer>
            <LeftRightWrapper>
              <LeftRightText leftText="Pacjent:" rightText="Imię i nazwisko" />
              <LeftRightText leftText="Adres:" rightText="Adres pacjenta" />
              <Box height={22} display={{ xs: 'none', md: 'block' }} />
              <LeftRightText
                leftText="Nr zlecenia"
                rightText={
                  <TooltipStyled
                    open={currentStep === 1}
                    title="<b>Nr zlecenia</b><br/>Poza podstawowymi danymi (imię, nazwisko, wiek, PESEL badanego, data przyjęcia zlecenia itp.) na wyniku wydawanym przez laboratoria sieci DIAGNOSTYKA znajduje się numer zlecenia. Pozwala on na szybkie zidentyfikowanie zlecenia i odnalezienie wyniku."
                  />
                }
              />
            </LeftRightWrapper>
            <LeftRightWrapper>
              <LeftRightText leftText="PESEL:" rightText="PESEL pacjenta" />
              <LeftRightText leftText="Data ur.:" rightText="1987-01-07" />
              <LeftRightText leftText="Płeć:" rightText="Żeńska" />
            </LeftRightWrapper>
          </LeftRightContainer>
          <Stack>
            <Divider sx={{ bgcolor: 'grey.50', mb: 4 }} />
            <TooltipsWithTextSection
              data={[
                {
                  open: currentStep === 2,
                  text: 'Badanie',
                  tooltipText:
                    '<b>Badanie</b><br/>W tej kolumnie umieszcza się nazwę wykonanego badania. Warto pamiętać, że na jedno badanie składa się oznaczenie szeregu parametrów. Tak jak np. w morfologii krwi.',
                  description: 'Morfologia',
                },
                {
                  open: currentStep === 3,
                  text: 'Wyniki',
                  tooltipText: '<b>Wyniki</b><br/>Jest wyrażony liczbowo, opisowo lub symbolami +/-',
                  description: '99',
                },
                {
                  open: currentStep === 4,
                  text: 'Jedn.',
                  tooltipText:
                    '<b>Jednostki</b><br/>Pokazuje w jakich jednostkach wyrażono wynik. Możliwa jest sytuacja gdy różne laboratoria używają różnych jednostek dla jednego rodzaju badania.',
                  description: 'tys/ul',
                },
                {
                  open: currentStep === 5,
                  text: 'Zakres referencyjny',
                  tooltipText:
                    '<b>Zakres referencyjny</b><br/>Wartości te wyrażają przedział w jakim powinien mieścić się prawidłowy wynik badania. Jeżeli wynik wykracza poza ten przedział – czyli jest niższy lub wyższy, oznacza to wynik nieprawidłowy.<br/><br/>Warto zwrócić uwagę że zakresy wartości prawidłowych mogą się różnić w zależności od wielu czynników np. zastosowanej metody do wykonania oznaczenia, wieku czy płci pacjenta. Jeśli wynik znajduje się pomiędzy ustalonymi wartościami granicznymi przyjmuje się że jest on prawidłowy.<br/><br/>Taki rezultat badania nie jest jednak podstawą do wykluczenia schorzenia. Dlatego wyniki powinny być interpretowane przez lekarza! Może on określić rodzaj problemu korzystając z kombinacji wyników innych badań, obrazu klinicznego pacjenta (stan zdrowia) czy pacjent przystąpił do badania prawidłowo przygotowany oraz szeregu innych czynników. Warto wspomnieć o często popełnianym błędzie - wyniki "pozytywny" badania obecności czynnika chorobotwórczego (np. wirusa zapalenia wątroby typu C) nie oznacza że wszystko jest w porządku ale wręcz przeciwnie stwiedzono infekcję.',
                  description: '3,8-10,0',
                  tooltipSize: 600,
                },
                {
                  open: currentStep === 6,
                  text: 'LIW',
                  tooltipText:
                    '<b>LIW</b><br/>W laboratoriach sieci DIAGNOSTYKA wyniki dodatkowo są oznaczane symbolami.<br/><br/>H (ang. high - wysoki) oznacza przekroczenie wartości max dla danego parametru.<br/><br/>L (ang. low - niski) oznacza przekroczenie wartości min (w dół) dla danego parametru.<br/><br/>Brak symbolu oznacza wynik prawidłowy, mieszczący sięw zakresie wartości prawidłowych.',
                  description: 'L',
                  tooltipSize: 500,
                },
              ]}
            />
          </Stack>
        </Stack>
        <Stack>
          <Divider sx={{ bgcolor: 'grey.50', mb: 4 }} />
          <TooltipsWithTextSection
            data={[
              {
                open: currentStep === 7,
                text: 'Badanie',
                tooltipText: '<b>Badanie</b><br/>Number badania',
                description: '1',
              },
              {
                open: currentStep === 8,
                text: 'Daty / Materiał',
                tooltipText:
                  '<b>Daty / Materiał</b><br/>Data wykonania - moment, w którym wynik z analizatora został przesłany do systemu e-lab (lub wprowadzony przez diagnostę przy badaniach manualnych)<br/><br/>Data/Godz wydania - moment ostatecznego zatwierdzenia przez diagnostę wyniku do publikacji/wydania<br/><br/>Materiał - rodzaj materiału w jakim zostało wykonane oznaczenie danego parametru',
                description:
                  'Data wykonania: 2014-12-15<br>Data/godz. wydania: 2015-12-15, 12:35:19<br>Materiał: Krew żylna (EDTA)',
              },
              {
                open: currentStep === 9,
                text: 'Autoryzował',
                tooltipText: '<b>Autoryzował</b><br/>Diagnosta, który zaakceptował wynik do publikacji',
                description: 'R. GRZELIK2, 04903',
              },
              {
                open: currentStep === 10,
                text: 'Wykonano',
                tooltipText: '<b>Wykonano</b><br/>Miejsce wykonania badania',
                description:
                  'Diagnostyka S.A., <br/>Laboratorium oddziału Kraków,<br/>ul. Życzkowskiego 16,<br/>31-864 Kraków',
                tooltipSize: 600,
              },
            ]}
          />
        </Stack>
        <Box sx={{ marginX: { xs: 'auto !important', md: 'initial !important' } }}>
          <TextWithTooltip
            open={currentStep === 11}
            text="<b>Brak uwag</b>"
            tooltipText="<b>Uwagi</b><br/>Miejsce na uwagi dotyczące materiału przekazanego do badań"
          />
        </Box>
        <Typography>
          * Oznacza, że zastosowano normy przynależne do przedziału wiekowego. Normy podane na wyniku odnoszą się do
          osób dorosłych, jeżeli nie wyszczególniono inaczej. Szczegółowe informacje dotyczące metodyki oznaczeń
          dostępne są w laboratorium
        </Typography>
      </Stack>
    </Box>
  );
};

export default ResultPresentation;
