import React, { ReactNode } from 'react';
import { Stack } from '@mui/material';

const LeftRightContainer = ({ children }: { children: ReactNode }): JSX.Element => {
  return (
    <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 4, md: 12 }} sx={{ '& > *': { flexBasis: '50%' } }}>
      {children}
    </Stack>
  );
};

export default LeftRightContainer;
