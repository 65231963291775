import React, { useState } from 'react';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import ResultPresentation from 'features/PatientGuideBook/components/HowToReadResultsContainer/ResultPresentation';

const HowToReadResultsContainer = (): JSX.Element => {
  const [isPresentationStarted, setIsPresentationStarted] = useState(false);

  return (
    <Box mt={8} mb={20}>
      <Container maxWidth="xl">
        <Stack spacing={20}>
          <Typography variant="h1" color="text.secondary">
            Jak czytać wyniki
          </Typography>
          <Stack spacing={4} alignItems="center">
            <Typography fontWeight="medium">Dowiedz się więcej o sprawozdaniu z wyników</Typography>
            <Button
              variant={isPresentationStarted ? 'outlined' : 'contained'}
              onClick={() => setIsPresentationStarted((prevState) => !prevState)}
              sx={{ height: 46 }}
            >
              {isPresentationStarted ? 'Zatrzymaj prezentację' : 'Rozpocznij prezentację'}
            </Button>
            <ResultPresentation start={isPresentationStarted} />
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default HowToReadResultsContainer;
