import React, { ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';

interface ILeftRightTextProps {
  leftText: string;
  rightText?: string | ReactNode;
}

const LeftRightText = ({ leftText, rightText }: ILeftRightTextProps): JSX.Element => {
  return (
    <Stack
      spacing={{ xs: 2, md: 6 }}
      direction={{ xs: 'column', md: 'row' }}
      justifyContent="space-between"
      textAlign={{ xs: 'center', md: 'left' }}
      sx={{ '& > *': { flexBasis: '50%' } }}
    >
      <Typography>{leftText}</Typography>
      <Typography
        component="div"
        fontWeight="medium"
        sx={{
          display: { xs: 'flex', md: 'initial' },
          justifyContent: 'center',
        }}
      >
        {rightText || '-'}
      </Typography>
    </Stack>
  );
};

export default LeftRightText;
